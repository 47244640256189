import { HeroVideoProps } from '@payfit/growth-platform-brand-system-v2/templates/HeroVideo';
import BlueCheck from 'apps/website/static/icon/blue_check.svg';
import { isList, isListItem, isParagraph } from 'datocms-structured-text-utils';
import { ButtonV2 } from 'libs/growth-platform-brand-system-v2/src/components/ButtonV2';
import { fontBody } from 'libs/growth-platform-brand-system-v2/src/styles/typographyNew';
import { classNames } from 'libs/growth-platform-brand-system-v2/src/utils/style';
import { ButtonTracking } from 'libs/growth-platform-brand-system-v2/src/utils/tracking/ButtonTracking';
import { classNamesVideoThumbnail } from 'libs/growth-platform-brand-system-v2/src/utils/videoUtils';
import { createSchema, morphism } from 'morphism';
import React from 'react';
import { StructuredText, renderRule } from 'react-datocms';
import ReactPlayer from 'react-player';
import { TrackingVideo } from '../../components/TrackingVideo';
import { schemaImage } from '../components/schemaImage';
import { schemaDatoButton } from '../schemaDatoButton';

export const schemaHeroVideo = createSchema<HeroVideoProps, any>({
  title: 'title',
  description: {
    path: 'description',
    fn: (propertyValue: any, source: any) => {
      if (propertyValue.value) {
        return (
          <StructuredText
            data={propertyValue || source?.description}
            customNodeRules={[
              renderRule(isParagraph, ({ children, key }) => (
                <span
                  key={key}
                  className={classNames(fontBody, 'text-navy-80')}
                >
                  {children}
                </span>
              )),
              renderRule(isParagraph, ({ children, key, ancestors }) => {
                if (isListItem(ancestors[0])) {
                  return (
                    <p key={key} className="inline">
                      {children}
                    </p>
                  );
                }
              }),
              renderRule(isList, ({ children, key }) => (
                <ul
                  key={key}
                  className={classNames(
                    fontBody,
                    'list-none list-outside flex flex-col gap-y-12 md:gap-y-16',
                  )}
                >
                  {children?.map((child) => (
                    <div>
                      <BlueCheck className="float-left mt-4" />
                      <div className="ml-[28px]">{child}</div>
                    </div>
                  ))}
                </ul>
              )),
            ]}
          />
        );
      }
      return null;
    },
  },
  activeModal: 'activeModal',
  ratings: 'ratings',
  ratingsDisclaimer: 'ratingsDisclaimer',
  ctaButtons: {
    path: ['ctaButton', 'locale'],
    fn: ({ ctaButton, locale }: any) => {
      if (ctaButton) {
        const btns = ctaButton.reduce((acc: any[], btn: any, key: number) => {
          if (btn?.href?.includes('app.payfit.live')) {
            const cta = morphism(schemaDatoButton, {
              ...btn,
              className: 'w-full md:w-auto hidden lg:flex',
              size: 'big',
              locale,
            });

            acc.push(
              <ButtonTracking
                component={ButtonV2}
                index={key}
                section="heroVideo"
                {...cta}
                labelTracking={cta.title}
                key={key}
              />,
            );
          } else {
            const cta = morphism(schemaDatoButton, {
              ...btn,
              className: classNames('w-full', 'md:w-auto'),
              size: 'big',
              locale,
            });
            acc.push(
              <ButtonTracking
                component={ButtonV2}
                index={key}
                section="heroVideo"
                {...cta}
                labelTracking={cta.title}
                key={key}
              />,
            );
          }

          return acc;
        }, []);

        return btns;
      }
      return;
    },
  },
  ctaDisclaimer: 'ctaDisclaimer',
  videoImage: {
    path: ['videoImage'],
    fn: (propertyValue, source) => {
      if (propertyValue?.url || propertyValue?.gatsbyImageData) {
        return morphism(schemaImage, {
          ...propertyValue,
          isHeader: true,
          pixelDensity: 'image_with_text',
        });
      }
      if (source?.videoImage?.url || source?.videoImage?.gatsbyImageData) {
        return morphism(schemaImage, {
          ...source.videoImage,
          isHeader: true,
          pixelDensity: 'image_with_text',
        });
      }
      return;
    },
  },
  VideoPlayer: {
    path: ['videoLink'],
    fn: ({ videoLink }) => {
      return <TrackingVideo url={videoLink} />;
    },
  },
  ShortVideo: {
    path: 'shortVideo',
    fn: (propertyValue) => {
      if (propertyValue) {
        return (
          <ReactPlayer
            url={propertyValue?.video?.mp4Url || propertyValue?.shortVideo?.url}
            className={classNamesVideoThumbnail}
            volume={0}
            muted={true}
            playing={true}
            loop={true}
            controls={false}
            config={{
              vimeo: {
                playerOptions: {
                  responsive: true,
                  title: false,
                  byline: false,
                  color: '0F6FDE',
                },
              },
            }}
          />
        );
      }
      return;
    },
  },
});
